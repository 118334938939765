<template>
  <div class="wrapper">
      <van-empty image="error" description="登录已过期" />
  </div>
</template>

<script>
import { Empty } from "vant";
export default {
  components:{
      [Empty.name]: Empty,
  },
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.wrapper>>>.van-empty{
    margin-top:100px
}
</style>